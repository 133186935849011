.values-container {
    padding: 40px 20px;
    text-align: center;
}

.our-values-header {
    max-width: 606px;
    margin: 0 auto;
}

.values-header h4 {
    color: #003b47;
    font-weight: bold;
    margin-bottom: 10px;
}

.values-header h2 {
    font-weight: bold;
    color: #003b47;
    margin-bottom: 10px;
    line-height: 1.4;
}

.values-header p {
    color: #4a4a4a;
    margin-bottom: 40px;
}

.values-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    margin: 0 auto;
}

.value-card {
    text-align: center;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
}

.value-card h3 {
    color: #012E33;
    text-align: center;
    font-family: Fredoka, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.22px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.value-card p {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 1.5;
}

.value-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.value-icon img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.values-slider-container {
    display: none;
}

@media (max-width: 1200px) {
    .values-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .values-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .values-grid {
        display: none;
    }

    .values-slider-container {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .values-slider {
        display: flex;
        transition: transform 0.3s ease-in-out;
    }

    .value-slide {
        flex: 0 0 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .slider-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.8);
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
    }

    .slider-button.prev {
        left: 10px;
    }

    .slider-button.next {
        right: 10px;
    }

    .slider-dots {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ccc;
        margin: 0 5px;
        border: none;
        cursor: pointer;
    }

    .dot.active {
        background-color: #003b47;
    }

    .value-icon {
        width: 60px;
        height: 60px;
    }

    .value-icon img {
        width: 30px;
        height: 30px;
    }

    .value-card h3 {
        font-size: 18px;
    }

    .value-card p {
        width: 170px;
        font-size: 14px;
    }
}

