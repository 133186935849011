.landing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #012E33;
    /* Match the background color */
    padding: 50px 20px;
    padding-bottom: 100px;
    /* height: 600px; */
    color: white;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    position: relative;
}

.tagline {
    font-size: 1rem;
    margin-bottom: 10px;
    font-style: italic;
}

.headline {
    font-weight: normal;
    margin: 10px 0;
    position: relative;
    max-width: 830px;
    color: #FFF;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.12px;
}


/* Emoji section */

.emoji-pos-robot {
    position: absolute;
    font-size: 40px;
    top: 40px;
    right: -70px;
    transform: rotateZ(20deg);
}

.emoji-pos-rocket {
    position: absolute;
    font-size: 40px;
    top: 40px;
    left: -70px;
    transform: rotateZ(20deg);
}

.emoji-pos-laptop {
    position: absolute;
    font-size: 40px;
    top: 170px;
    left: -10px;
    transform: rotateZ(-30deg);
}

.emoji-pos-book {
    position: absolute;
    font-size: 40px;
    top: 170px;
    right: -10px;
    transform: rotateZ(20deg);
}

.about-hero-img {
    width: 100%;
    display: flex;
    margin-top: -150px;
    z-index: 1;
}

.about-hero-img img {
    object-fit: contain;
    width: 100%;
}

.description {
    font-size: 1rem;
    margin: 10px 0;
    max-width: 700px;
    color: #678285;
    text-align: center;
    font-family: Fredoka;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 145.455% */
    letter-spacing: -0.22px;
}

.button-container {
    display: flex !important;
    gap: 10px;
    margin-top: 20px;
}

.about-hero-img-container {
    position: absolute;
    top: 300px;
    width: 100%;
}

.about-hero-img-container img {
    height: 600px;
    width: 100%;
}

@media (max-width: 1024px) {
    .about-hero-img {
        margin-top: -150px;
    }
}

@media (max-width: 936px) {
    .emoji-pos-robot {
        display: none;
    }

    .emoji-pos-laptop {
        display: none;
    }

    .emoji-pos-book {
        display: none;
    }

    .emoji-pos-rocket {
        display: none;
    }
}

.about-hero-img-2 {
    display: none;
}

@media (max-width: 604px) {
    .about-hero-img {
        margin-top: -100px;
    }
}


@media (max-width: 764px) {

    .about-hero-img-1 {
        display: none;
    }

    .about-hero-img-2 {
        display: block;
    }

    .headline {
        font-size: 40px;
    }

    .description {
        font-size: 18px;
    }




    .mission-sub {
        font-size: 40px;
    }

    .card-section {
        grid-template-columns: 1fr;
    }

    .emoji-pos-robot {
        display: none;
    }

    .emoji-pos-laptop {
        display: none;
    }

    .emoji-pos-book {
        display: none;
    }

    .emoji-pos-rocket {
        display: none;
    }
}

@media (max-width: 426px) {
    .button-container {
        flex-direction: column;
        width: 100%;
    }
}