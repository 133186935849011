.cta-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.cta-button.secondary {
    background-color: transparent;
    color: #FC823E;
    border: 1px solid #FC823E;
    border-radius: 16px;
    font-family: fredoka;
}

.cta-button:hover {
    opacity: 0.9;
}