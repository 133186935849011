@import url('https://fonts.googleapis.com/css2?family=Fredoka:wdth,wght@75..125,300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hero-title {
  @apply text-[#FFF] fredoka text-[40px] md:text-[56px] font-semibold leading-normal tracking-[-1.12px]
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav-logo-title {
  color: #FFF;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 30.261px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.261px;
  /* 100% */
}


.embla__slide {
  flex: 0 0 calc(100% / 4);
  min-width: 0;
}


.fredoka {
  font-family: "Fredoka", serif !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* about landing page */


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.our-initiatives {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.our-mission-box {
  margin: 0 auto;
  /* padding: 0 30px; */
  max-width: 1024px;
}

.mission-title {
  color: #012E33;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Fredoka;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.22px;
}

.mission-sub {
  color: #012E33;
  font-size: 56px;
  font-weight: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
}

.initiative-title {
  color: #012E33;
  text-align: center;
  font-family: fredoka;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.About {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.team-member-name {
  text-align: left;
  color: #012E33;
  font-family: Fredoka;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 145.455% */
  letter-spacing: -0.22px;
}

.team-member-role {
  text-align: left;
  color: #678285;
  font-family: Fredoka;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  letter-spacing: -0.36px;
}

.team-member-cont {
  margin-top: -20px;
  margin-left: -10px;
}

/* Mission section */

/* About Card css */

.card-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  /* padding: 20px; */
  background-color: #f9fafc;
  /* Light background color */
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  border-radius: 24px;
  background: linear-gradient(136deg, #FFF 8.91%, #F7F7F7 50%);
}


.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #003b47;
  /* Dark green text color */
}

.card-description {
  font-size: 1rem;
  color: #4a4a4a;
  /* Grey text color */
  line-height: 1.5;
}

.initiative-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initiative-btn-container button {
  display: inline-flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #FC823E;
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.40) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.35) inset;
  cursor: pointer;
}



@media (max-width: 764px) {
  .initiative-btn-container button {
    width: 100%;
  }
}


/* footer */

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-size: 18px;
  font-weight: bold;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Main Content Styles */
main {
  flex-grow: 1;
  padding: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}

.grid-item {
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 5px;
}

.grid-item h3 {
  margin-bottom: 10px;
}

.grid-item ul {
  list-style-type: none;
}

.grid-item ul li a {
  color: #0C6169;
  text-decoration: none;
}

.newsletter {
  background-color: #0C6169;
  color: #fff;
  padding: 40px;
  text-align: center;
  margin-top: 40px;
}

.newsletter h3 {
  margin-bottom: 20px;
}

.newsletter form {
  display: flex;
  justify-content: center;
}

.newsletter input {
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  width: 300px;
}

.newsletter button {
  background-color: #fff;
  color: #0C6169;
  padding: 10px 20px;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

/* Footer Styles */
footer {
  background-color: #0C6169;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.social-links {
  margin-bottom: 10px;
}

.social-links a {
  margin: 0 10px;
}

.social-links img {
  height: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .newsletter input {
    width: 200px;
  }
}

@media (max-width: 480px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  nav ul li {
    margin: 10px 0;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .newsletter input {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .newsletter button {
    border-radius: 5px;
  }
}


/* ----------------- Footer ------------------- */

/* Footer Styles */
.footer {
  background-color: #f4f4f4;
  padding: 40px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.footer-logo img {
  width: 189px;
  height: auto;
}

.footer-logo h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #044047;
  font-style: italic;
}

.footer-nav {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: safe;
}

.footer-section h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #044047;
  margin-bottom: 6px;
  text-transform: capitalize;
  text-align: left !important;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.footer-section li {
  text-align: left !important;
}

.footer-section li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.16px;
  text-align: start !important;
  color: #678285;
  text-decoration: none;
  text-transform: capitalize;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dcdcdc;
  padding-top: 24px;
}

.footer-copyright {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.16px;
  color: #678285;
}

.footer-social {
  display: flex;
  align-items: center;
  gap: 28px;
}

.footer-social span {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.16px;
  color: #678285;
}

.footer-social a {
  display: block;
  width: 32px;
  height: 32px;
}

.footer-social a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contact-box {
  display: flex;
  gap: 30px;
}

@media (max-width: 768px) {
  .footer-nav {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-bottom {
    flex-direction: column;
    gap: 20px;
  }

  .footer-social {
    flex-direction: column;
  }
}

/* =========== Footer end ============== */

/* =========== Navbar Start ============ */

.header {
  background-color: #fff;
  color: #012E33;


}

.navbar-cont {
  display: flex;
  padding: 10px !important;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: white;
  position: relative;
}

.nav-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-solution {
  position: relative;
}

.nav-links a {
  color: #FFF;
  text-align: center;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
  color: #012E33;
  letter-spacing: -0.16px;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}


.login-btn {
  padding: 8px 24px;
  background-color: transparent;
  color: #012E33;
  border: 1px solid #012E33;
  border-radius: 16px;
  cursor: pointer;
  font-family: fredoka !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-btn:hover {
  background-color: white;
  color: #003d3d;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 0.3rem;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background: white;
  border-radius: 3px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #003d3d;
    position: absolute;
    top: 60px;
    right: 10px;
    padding: 1rem;
    border-radius: 5px;
  }

  .nav-links.active {
    display: flex;
    height: 100vh;
    background-color: white;
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
    width: 50%;
    padding: 40px 20px;
  }

  .nav-links.active a {
    color: black;
  }

  .hamburger {
    display: flex;
  }

  .login-btn {
    margin-left: auto;
    margin-right: 10px !important;
  }
}

/* =========== Navbar End ============ */