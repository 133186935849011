.cta-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.cta-button.primary {
    background-color: #FC823E;
    padding: 8px 24px;
    color: white;
    border-radius: 16px;
    font-family: fredoka;
}

.cta-button:hover {
    opacity: 0.9;
}

@media (max-width: 768px) {
    .cta-button {
        width: 100%;
    }
}