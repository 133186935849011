.solutionCard {
    width: 350px;
    border-radius: 32px;
    background: #FFF;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 2;
    top: 70px;
    display: none;
}

.sol-active {
    display: block;
}

.service-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 24px;
    cursor: pointer;
}

.service-item:last-child {
    margin-bottom: 0;
}

.icon-container {
    padding: 8px;
    background-color: #FFF3E0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    color: #FF9800;
}

.content {
    display: flex;
    flex-direction: column;
}

.title {
    color: #012E33;
    font-family: Fredoka;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 145.455% */
    letter-spacing: -0.22px;
}

.sol-description {
    color: #678285;
    font-family: Fredoka;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
}