.mobile-sidebar {
  position: relative;
  width: 100%;
  /* width: 100vw; */
}

.mobile-nav {
  padding: 1rem;
  justify-content: space-between;
  gap: 1rem;
  max-width: 100vw;
  margin: 0 auto;
  position: absolute;
  right: -8px;
  top: 39px;
  width: 100vw !important;
  min-height: 90vh;
  background: white;
  z-index: 2;
  display: none;
}

.mobile-active {
  display: flex;
  flex-direction: column;
}

.menu-container {
  /* height: calc(100vh - 150px) */
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #E5E7EB;
  gap: 1rem;
  color: #004953;
  font-size: 1.25rem;
  font-weight: 500;
}

.icon-wrapper {
  width: 50px;
  height: 50px;
  background-color: #E6F4F1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  color: #FF7A5C;
}

.dropdown-arrow {
  width: 20px;
  height: 20px;
  margin-left: auto;
  color: #004953;
}

.nav-text {
  flex-grow: 1;
}

.dropdown-arrow {
  width: 24px;
  height: 24px;
  color: #004953;
  transition: transform 0.3s ease;
}

.solutions.active .dropdown-arrow {
  transform: rotate(180deg);
}

.solutions-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* margin-left: 1rem; */
  background: #E6F4F1;
  border-radius: 8px;
}

.solutions.active .solutions-content {
  max-height: 500px;
  margin-top: 1rem;
}

.solution-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
}

.solution-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.solution-text {
  display: flex;
  flex-direction: column;
}

.solution-title {
  font-size: 1rem;
  font-weight: 500;
  color: #004953;
}

.solution-subtitle {
  font-size: 0.875rem;
  color: #64748B;
}

.button-container-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.contact-button {
  width: 100%;
  padding: 1rem;
  border: 2px solid #FF7A5C;
  border-radius: 2rem;
  color: #FF7A5C;
  font-weight: 600;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.contact-button:hover {
  background: rgba(255, 122, 92, 0.1);
}

.trial-button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 2rem;
  background: #FF7A5C;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.trial-button:hover {
  background: #ff6643;
}