.free-trial {
    background-image: url(/public/images/about/free-trial-img-1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 568px;
    padding: 0 16px;
    border-radius: 50px;
    position: relative;
}

.free-trial-box-parent {
    width: 100%;
    /* display: flex;
    margin-top: 60px;
    justify-content: end; */
}

.free-trial .free-trial-box {
    right: 20px;
    bottom: 10px;
    border-radius: 32px;
    background: #FEF991;
    padding: 30px;
    width: fit-content;
    position: absolute;
    /* width: 191px;
    height: 170px; */
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.free-trial-box h4 {
    color: #012E33;
    max-width: 160px;
    margin: 0;
    text-align: center;
    font-family: fredoka;
    font-size: 22px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.22px;
}

.free-trial .free-trial-box p {
    color: #FC823E;
    font-family: fredoka;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    letter-spacing: -1.12px;
}

.free-trial .free-trial-text {
    max-width: 500px;
    padding: 44px;
}

.free-trial .free-trial-text h1 {
    color: #FFF;
    font-family: "Fredoka One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
}


.ft-btn-container {
    margin-top: 20px;
}

@media (max-width: 1024px) {
    /* .free-trial .free-trial-box-parent {
        margin-bottom: 70px !important;
        position: absolute;
        right: 10px;
        bottom: 20px;
        padding: 0;
        margin: 0;
    } */

    .free-trial .free-trial-box-parent .free-trial-box {
        gap: 10px
    }

    .free-trial .free-trial-box-parent .free-trial-box p {
        font-size: 30px;
    }

    .free-trial .free-trial-box-parent .free-trial-box h4 {
        font-size: 17px;
        margin: 0;
    }

}

@media (max-width: 768px) {
    .free-trial {
        background-image: url(/public/images/about/free-trial-img-1.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 568px;
        padding: 0 16px;
    }


}

@media (max-width: 425px) {
    .free-trial {
        background-image: url(/public/images/about/free-trial-img-2.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 568px;

    }

    .free-trial .free-trial-text {
        padding: 40px 10px 0px 10px !important;
    }

    .free-trial .free-trial-text h1 {
        font-size: 25px;
    }

    .ft-btn-container {
        margin-top: 20px;
        width: 100%;
    }

    .free-trial .free-trial-text {
        width: 100%;
        padding: 44px;
    }

    .free-trial-box-parent .free-trial-box h4 {
        font-size: 17px !important;
    }

    .free-trial-box-parent .free-trial-box {
        width: 150px;
        border-radius: 10px;
    }

    .free-trial-box-parent .free-trial-box p {
        font-size: 20px !important;
    }

}