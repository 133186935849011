.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__viewport {
  overflow: hidden;
  max-width: 1200px;
  height: 300px;
  margin: 0 auto;
  margin-top: 50px;
}

.embla__container {
  display: flex;
  height: 100%;
  width: 100%;
}

.embla__slide {
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}